<template>
    <section>
        <div class="richtext">
            
          <div class="app-card">
            <img
              class="card-image"
              src="~@/assets/images/waste-water.png"
              alt=""
            />
            <div class="card-texts">
              <h3>Waste Water Treatment</h3>
              <p>
                We respond efficiently to our customers' needs in a wide variety
                of fields, from the most common water treatment to predision
                Chemical Processing.
              </p>
            </div>
          </div>
          <div class="app-card">
            <img
              class="card-image"
              src="~@/assets/images/waste-water.png"
              alt=""
            />
            <div class="card-texts">
              <h3>Waste Water Treatment</h3>
              <p>
                We respond efficiently to our customers' needs in a wide variety
                of fields, from the most common water treatment to predision
                Chemical Processing.
              </p>
            </div>
          </div>
          <div class="app-card">
            <img
              class="card-image"
              src="~@/assets/images/waste-water.png"
              alt=""
            />
            <div class="card-texts">
              <h3>Waste Water Treatment</h3>
              <p>
                We respond efficiently to our customers' needs in a wide variety
                of fields, from the most common water treatment to predision
                Chemical Processing.
              </p>
            </div>
          </div>
          <div class="app-card">
            <img
              class="card-image"
              src="~@/assets/images/waste-water.png"
              alt=""
            />
            <div class="card-texts">
              <h3>Waste Water Treatment</h3>
              <p>
                We respond efficiently to our customers' needs in a wide variety
                of fields, from the most common water treatment to predision
                Chemical Processing.
              </p>
            </div>
          </div>
        </div>
    </section>
</template>
<script>
export default {
  title: "Application",
}
</script>